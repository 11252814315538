import { GlobalHoverService } from 'o365-vue-services';
import WorkflowHover from 'workflow.vue.components.WorkflowHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        service.bindElement(el, {
            component: WorkflowHover,
            props: {
                workflowId: binding.value,
            },
        })
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el)
        if (props && props.workflowId !== binding.value) {
            props.workflowId = binding.value;
        }
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);
    }
};